
import React, { useState } from 'react'
import 'bootstrap';
import './Contact.css';
import Button from 'react-bootstrap/Button';
import {FaMobileAlt} from "react-icons/fa";
import {GrLocation} from "react-icons/gr";
import {BsEnvelope} from  "react-icons/bs";
import Alert from 'react-bootstrap/Alert';
import Footer from './Footer';

const  Contact = () => {


  const [show,setShow] = useState(false);
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [subject,setSubject] = useState("");
  const [message,setMessage] = useState("");
  const sendEmail = async(e)=>{
    e.preventDefault();

    const res = await fetch("email1.surajkumawat.com/register", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        }, body: JSON.stringify({
            name,
            email,
            subject,
            message
        })
    });

    const data = await res.json();
    console.log(data);

    if (data.status === 401 || !data) {
        console.log("error")
    } else {
        setShow(true);
        setEmail("");
        setName("")
        console.log("Email sent")
        
    }
}


  return (
    <>
    {
        show ? <Alert variant="primary" onClose={() => setShow(false)} dismissible>
            Your Email Succesfully Send
        </Alert> : ""
    }
    <div className='page_wrapper'>
  

      <div className='resumehead_inner'>
        <h1 className='headResume'>Contact</h1>
      </div>
      <div className='directframe'>
      <iframe src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d1836.8974203939417!2d76.0379663!3d22.9577816!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x396317226b2c42df%3A0x47098e396f961017!2sExpound%20Soft%20Tech%20Solution%20Private%20Limited%20267%20Ram%20Nagar%20Extension%2C%20Mishrilal%20Nagar%2C%20Ganga%20Nagar%20Dewas%2C%20Madhya%20Pradesh%20455001!3m2!1d22.9577816!2d76.0379663!5e0!3m2!1sen!2sin!4v1670931414139!5m2!1sen!2sin" 
        width="100%"
        height="150"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade">
           </iframe>
       </div>


      <div className='main_contact_inner'>
        <div className='anyhead'>
          <div className='contact_infomation'>
            <div className='contact_icon'>
          <FaMobileAlt />
          </div>
          <div className='contect_info'>
            <h6>+91 999-344-0865</h6>
            
          </div>
          </div>
          <div className='contact_infomation'>
          <div className='contact_icon'>
          <GrLocation />
          </div>
          <div className='contect_info'>
            <h6>267 A, Ram Nagar Extension, Mishrilal Nagar,
           Ram  Nagar, Dewas, Madhya Pradesh 455001</h6>
            
          </div>
          </div>
          <div className='contact_infomation'>
          <div className='contact_icon'>
          <BsEnvelope />
          </div>
          <div className='contect_info'>
            <h6>suraj@surajkumawat.com</h6>
           
          </div></div>

          <div className='contact_infomation'>
           
          </div>
</div>
          <div className='contact-form'>
            <form action="#" method="POST" className='contactinput'>
              <div className='contact_column'>
              <h3 class="block-title">How Can I Help You?</h3>
              <p>
                <input
                  className='name'
                  type="text"
                  name="Your Name"
                  placeholder='Full Name'
                  value={name}
                 onChange={(e)=>setName(e.target.value)}
                  autoComplete='off'
                  required /></p>
            
              <p>
                <input
                  className='name'
                  type="email" 
                  name="Your Email"
                  placeholder='Email Address'
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  autoComplete='off'
                  required /> 
                  </p>
              <p>

                <input
                  className='name'
                  type="text"
                  name="subject"
                  placeholder='Subject'
                  value={subject}
                  onChange={(e)=>setSubject(e.target.value)}
                  autoComplete='off'
                  required />
              </p>
              </div>
              <div className='contact_column'>
              <p>
                <textarea
                  className='name'
                  name="message"
                  cols="30"
                  placeholder=' Message'
                  rows="6"
                  value={message}
                  onChange={(e)=>setMessage(e.target.value)}
                  autoComplete='off'
                  required></textarea></p>
                  </div>
              <div className='btnsend'>
                {/* <a target="_blank" href="suraj@surajkumwat.com" 
                className='sendmsg'>Send message</a> */}
                <Button  type="submit"
                 className='sendmsg' onClick={sendEmail}>
                Send message
                </Button>
              </div>
            </form>
          </div>
        </div>

      <Footer />
    </div>

</>


  )
}

export default Contact;