

import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from './Home';
import Resume from './resume';
import Layout from './Layout';
import Contact from './contact';
import Portfolio from './portfolio';




export default function Menu() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        <Route path='/resume' element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
      
        </Route>
      </Routes>
    </BrowserRouter>
  );
}