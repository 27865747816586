import React from 'react';
 import './Footer.css';
 import 'bootstrap';



 function Footer() {
     return (

       <div className='site-footer'>
        <div className='footer'>
        <ul className='footerul'>
          <li className='lifooter'>
            <a href="https://www.twitter.com/Surajkumawat/"  target="_blank">Twitter</a></li>
          <li  className='lifooter'> <a href="https://www.facebook.com/ersurajkumawat/"  target="_blank" >Facebook</a></li>
          <li  className='lifooter'> <a href="https://www.instagram.com/kumawatsuraj/"  target="_blank">Instgram</a></li>
        </ul>
        <div className='footer-copyrights'>
          <p className='footerp'>© 2022 All rights reserved. 
          <a href="https://expoundsofttechsolution.com/"  target="_blank">ESTS.</a></p> </div>
      </div>
      </div>
     )
 }
 export default Footer;