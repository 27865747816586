export const GalleryData = [
    {
        id:1,
        titile:"Shopify",
        image:"./Images/New-Project-12.webp"
    },
    {
        id:2,
        titile:"Shopify",
        image:"./Images/New-Project-11.webp"
    },
    {
        id:3,
        titile:"Shopify",
        image:"./Images/skechersshoes.png"
        
    },  
    {
        id:4,
        titile:"Shopify",
        image:"./Images/New-Project-10.webp"
    },
    {
        id:5,
        titile:"Magento",
        image:"./Images/noblag.png"
    },
    {
        id:6,
        titile:"Magento",
        image:"./Images/listenup.png"
    },
    {
        id:7,
        titile:"Magento",
        image:"./Images/formverk.png"
    },
    {
        id:8,
        titile:"Wordpress",
        image:"./Images/New-Project-18.webp"
    },
    {
        id:9,
        titile:"Wordpress",
        image:"./Images/hoppbox.png"
    },
    
]