import React from 'react';
import { Outlet, Link} from "react-router-dom";
import 'bootstrap';
import Slider from "react-slick";
import { RxMobile } from "react-icons/rx";
import { CgDigitalocean,CgAwards,CgCoffee } from "react-icons/cg";
import { BsDisplay,BsLaptop,BsHourglassTop } from "react-icons/bs";
import { RiEmotionHappyLine } from "react-icons/ri";
import Footer from './Footer';



function Home() {

 
 const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    }
  };

  const setting1 = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2,
    swipeToSlide: true,
    dots: true,
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: ; color: `
      );
    }
  };
  return (
    <div className='page_wrapper'>
<div class="banner_innner">
  <div class="banner_imges">
      <div class="banner_inner_row">
      <img src="./Images/SurajKumawat.jpg" alt="banner image" />
      </div>
    </div>
    <div class="banner_info">
      <div class="banner_info_col">
      <h3 class="titleStyle">Suraj Kumawat</h3>
      <p class="headerFont">I am Suraj Kumawat and I have 11+ years of experience in the IT industry. I am expert in Magento, Shopify, React, and Node. I take pride in my work and always deliver high quality results. I have worked extensively on frameworks/CMS like Magento, Shopify, React, NodeJS, Angular JS. In addition to this, I am also an expert in Payment Gateway Integration, API Integration, and Database Management. I have completed more than 200 projects in my career so far and have always strived to deliver the best possible results to my clients. I am a team player and have a positive attitude. I am always willing to help out and share my knowledge with others. I am a reliable and hardworking individual who always put the needs of the team first and trustworthy developer that you can count on to get the job done right.</p>
      <div class="button">
      <Link to="/resume" class="downloadcv">Download CV</Link>
        <Link to="/contact" class="contact">Contact </Link>
      </div>
      <Outlet />
    </div>
  </div>
</div>


<div class="main_whatido_innerr">
     <h3 class="block-title">What I Do</h3>
     <div class="about_whataredo_info">
         <div class="about_whataredo_info_colum">
             <div class="whatido_infomation">
             <div class="icon"><BsLaptop /></div>
                 <div class="what_i_do_detail">
                     <h6 class="whaterheading">Web Design</h6>
                     <p>Web design refers to the design of websites that are displayed on the internet. It 
                        usually refers to the user experience aspects of website development rather than software development. Web design used to be focused on designing websites for desktop browsers; however, since the mid-2010s, design for mobile and tablet browsers has become ever-increasingly important..</p>
                 </div>
             </div>
         </div>
         <div class="about_whataredo_info_colum">
             <div class="whatido_infomation">
             <div class="icon"><BsDisplay /></div>
                 <div class="what_i_do_detail">
                     <h6 class="whaterheading">Web Development</h6>
                     <p>Web development refers to the creating, building, and maintaining of websites. It includes aspects such as web design, web publishing, web programming, and database management. It is the creation of an application that works over the internet i.e. websites.</p>
                 </div>
             </div>
         </div>
         <div class="about_whataredo_info_colum">
             <div class="whatido_infomation">
             <div class="icon"><RxMobile /></div> 
                 <div class="what_i_do_detail">
                     <h6 class="whaterheading">Mobile APP development</h6>
                     <p>Mobile application development is the process of creating software applications that run on a mobile device, and a typical mobile application utilizes a network connection to work with remote computing resources..</p>
                 </div>
             </div>
         </div>
         <div class="about_whataredo_info_colum">
             <div class="whatido_infomation">
             <div class="icon"><CgDigitalocean /></div>
                 <div class="what_i_do_detail">
                     <h6 class="whaterheading">Digital Marketing</h6>
                     <p>Any marketing that uses electronic devices and can be used by marketing specialists to convey promotional messaging and measure its impact through your customer journey. In practice, digital marketing typically refers to marketing campaigns that appear on a computer, phone, tablet, or other device.</p>
                 </div>
             </div>
         </div>
     </div>
 </div>

 <div class="main_funfacts">
    <h3 class="block-title">Fun Facts</h3>
    <div class="fun_fact_inner">
        <div class="fun_fact_inner_row">
            <div class="fun_fact_inner_column">
                 <div class="funfacts-column_cart">
                   <RiEmotionHappyLine />
                    <p>Happy Clients</p><span class="lm-info-block-value">800+</span>
                </div>
            </div>
            <div class="fun_fact_inner_column">
            <div class="funfacts-column_cart">
                <BsHourglassTop />
                    <p>Working Hours</p><span class="lm-info-block-value">8000+</span>
                </div>
            </div>
            <div class="fun_fact_inner_column">
            <div class="funfacts-column_cart">
                <CgAwards />
                    <p>Awards Won</p><span class="lm-info-block-value">10+</span>
                </div>
            </div>
            <div class="fun_fact_inner_column">
            <div class="funfacts-column_cart">
                <CgCoffee />
                    <p>Coffee Consumed</p><span class="lm-info-block-value">1350+</span>
                </div>
            </div>
        </div>
    </div>
</div>
      
      <div class="main_client_inner">
      <h3 class="block-title">Clients</h3>
      <div className="main_client_inner">
        <Slider {...settings}>
        
          <div className='client_column'>
            <img src="./Images/logo1.png" height='35px' />
          </div>
          <div className='client_column'>
            <img src="./Images/logo2.png" height='35px' />
          </div>
          <div className='client_column'>
            <img src="./Images/logo3.png" height='35px' />
          </div>
          <div className='client_column'>
            <img src="./Images/logo4.png" height='35px' />
          </div>
          <div className='client_column'>
            <img src="./Images/logo5.png" height='35px' />
          </div>
          <div className='client_column'>
            <img src="./Images/logo6.png" height='35px' />
          </div>
          <div className='client_column'>
            <img src="./Images/logo7.png" height='35px' />
          </div>
        </Slider>
      </div>
      </div>
     

<div className='testimonials'>
      <h3 class="block-title">Testimonials</h3>
        <div className='testimonial_main-inner'>
          <Slider {...setting1}>

            <div className="testimonial_column">

              <img className="testimonial-img" src="./Images/944179_1394080464138103_1149005383_n.jpg" alt="img" />
              <div className="testimonial_info">
                <p>My company, Noblag, has been working with the Suraj Kumawat for more than 3 years as our only developer. They are very qualified, competent, skillful, and great communication skills. They are responsible and deliver on time and within budget. I would recommend them to any other companies, friends, and families..</p>
                <h5>Rhoxann Witz</h5>
                <p>Noblag.com</p>
              </div>
            </div>
            <div className="testimonial_column">

              <img className="testimonial-img"
                src="./Images/87606953_10157795432097870_3107240583312179200_n.jpg" alt="img" />
              <div className="testimonial_info">
                <p>Suraj and his team are fantastic! After hiring a number of international developers before meeting him and his team, I can honestly say his team are the best I have found and are at the top of the pack when it comes to all of your website and app needs! I highly recommend them to anyone requiring those services.</p>
                <h5>Aaron Solito</h5>
                <p>YesThanks.com.au</p>
              </div>
            </div>
            <div className="testimonial_column">

              <img className="testimonial-img" src="./Images/10580704_10153814663316369_6669840736188880831_o.jpg" alt="img" />
              <div className="testimonial_info">
                <p>Suraj is amazing. Have been working with him for years now and no complaint at all. Very quick turnaround with projects, very responsive and reliable. He's very knowledgeable. We're ending this project because it was successfully completed and we're starting a new contract."</p>
                <h5>Chichi Ifeakandu </h5>
                <p>getkeysmart.com</p>
              </div>
            </div>

          </Slider>
        </div>
      </div>

      
      <Footer />
    </div>




  );
}






export default Home;