import React from 'react'
import 'bootstrap';
import './Portfolio.css';
import Footer from './Footer';
import { GalleryData } from "./GalleryData";
import { useEffect, useState } from "react";

 
function Portfolio() {

  const [data,setData] = useState([]);
  const [collection,setCollection] = useState([]);

  useEffect(()=>{
    setData(GalleryData);
    setCollection([... new Set(GalleryData.map((item)=> item.titile))])
  },[]) 

  const gallery_filter = (itemData) =>{
    const filterData = GalleryData.filter((item)=> item.titile == itemData);
    setData(filterData);
  }
  return (
    <div className='page_wrapper'>

       <div className='resumehead_inner'>
       <h1 className='headResume'>PortFolio</h1>
  </div>
<div className="galleryWrapper">
  <div className='filterItem'>
  <ul>
            <li><button onClick={()=> setData(GalleryData)}>All</button></li>
            {
              collection.map((item)=> <li><button onClick={()=>{gallery_filter(item)}}>{item}</button></li>)
            }
          </ul>
  </div>
<div className="galleryContainer">
{
            data.map((item)=> <div  key={item.id} className="galleryItem"><img src={item.image  } /></div> )
          }
</div>
</div>
      <Footer />
        </div>
        
     );
   }

   export default Portfolio;