import React from 'react';
import 'bootstrap';
import './Resume.css';
import ProgressBar from 'react-animated-progress-bar';
import Footer from "./Footer"

function Resume() {


     return (
          <div className='page_wrapper'>

               <div className='resume_header_inner'>
                    <div class="page-title">
                         <h1>Resume</h1>
                         <div class="page-subtitle">
                              <h4> 11 Years of Experience</h4>
                         </div>
                    </div>
               </div>
               <div className='main_resume'>
                    <div className='main_skillsdesign'>
                         <div className='certificates_headin'>
                              <h3 class="block-title">Design Skills</h3></div>
                         <div className='certificates_headin'>
                              <h3 class="block-title">Coding Skills</h3></div>
                         <div className='main_skillsdesign_row'>
                              <div className='main_skillsdesign_col'>
                                   <h6>UI/UX Design</h6>
                                   <ProgressBar
                                        width="100%"
                                        height="10px"
                                        rect
                                        percentage="95"
                                        rectPadding="1px"
                                        rectBorderRadius="20px"
                                        rectBorder="1"
                                   />
                              </div>
                              <div className='main_skillsdesign_col'>
                                   <h6>React Native</h6>
                                   <ProgressBar
                                        width="100%"
                                        height="10px"
                                        rect
                                        percentage="96"
                                        rectPadding="1px"
                                        rectBorderRadius="20px"
                                   />
                              </div>
                              <div className='main_skillsdesign_col'>
                                   <h6>HTML / CSS</h6>
                                   <ProgressBar
                                        width="100%"
                                        height="10px"
                                        rect
                                        percentage="91"
                                        rectPadding="1px"
                                        rectBorderRadius="20px"
                                   />
                              </div>
                              <div className='main_skillsdesign_col'>
                                   <h6>Wordpress</h6>
                                   <ProgressBar
                                        width="100%"
                                        height="10px"
                                        rect
                                        percentage="90"
                                        rectPadding="1px"
                                        rectBorderRadius="20px"
                                   />
                              </div>
                              <div className='main_skillsdesign_col'>
                                   <h6>PHP</h6>
                                   <ProgressBar
                                        width="100%"
                                        height="10px"
                                        rect
                                        percentage="92"
                                        rectPadding="1px"
                                        rectBorderRadius="20px"
                                   />
                              </div>
                              <div className='main_skillsdesign_col'>
                                   <h6>Magento ( Adobe Commerce )</h6>
                                   <ProgressBar
                                        width="100%"
                                        height="10px"
                                        rect
                                        percentage="94"
                                        rectPadding="1px"
                                        rectBorderRadius="20px"
                                   />
                              </div>
                              <div className='main_skillsdesign_col'>
                                   <h6>JavaScript</h6>
                                   <ProgressBar
                                        width="100%"
                                        height="10px"
                                        rect
                                        percentage="90"
                                        rectPadding="1px"
                                        rectBorderRadius="20px"
                                   />
                              </div>
                              <div className='main_skillsdesign_col'>
                                   <h6>NodeJS</h6>
                                   <ProgressBar
                                        width="100%"
                                        height="10px"
                                        rect
                                        percentage="93"
                                        rectPadding="1px"
                                        rectBorderRadius="20px"
                                   />
                              </div>
                              <div className='main_skillsdesign_col'>
                                   <h6>Shopify</h6>
                                   <ProgressBar
                                        width="100%"
                                        height="10px"
                                        rect
                                        percentage="95"
                                        rectPadding="1px"
                                        rectBorderRadius="20px"
                                   />
                              </div>
                              <div className='main_skillsdesign_col'>
                                   <h6>Angular</h6>
                                   <ProgressBar
                                        width="100%"
                                        height="10px"
                                        rect
                                        percentage="92"
                                        rectPadding="1px"
                                        rectBorderRadius="20px"
                                   />
                              </div>
                         </div>
                    </div>

                    <div className='certificates_inner'>
                         <h3 class="block-title">Partners</h3>

                         <div className='certificates_row'>
                              <div className='certificates_col'>
                                   <div className='certificates-column_cart'>
                                        <div className='certi-logo'>
                                             <img src="./Images/Big-Commerce.jpg" />
                                        </div>
                                        <div className='certigicates_info'>
                                             <h4>BIG-commerce</h4>

                                        </div>
                                   </div>
                              </div>
                              <div className='certificates_col'>
                                   <div className='certificates-column_cart'>
                                        <div className='certi-logo'>
                                             <img src="./Images/images.jpg" />
                                        </div>
                                        <div className='certigicates_info'>
                                             <h4>Shopify</h4>

                                        </div>
                                   </div></div>
                         </div>
                    </div>
                    <div className='education_resume'>


                         <div className='education_resume_inner'>

                              <div className='education_column_resume'>
                                   <div className='certificates_headin'>
                                        <h3 class="block-title">Education</h3>
                                   </div>
                                   <div className='education_resume_col'>
                                        <h5 className="item-period">  2007-2011</h5>
                                        <span class="eduction_title">University of Studies</span>
                                        <h6 className='item-title'>Malwa Institute of Technology,Nipaniya Bypass, Indore</h6>
                                        <p className='Text'>Bachelor of Engineering - BE, Information Technology</p>
                                   </div>
                              </div>
                              <div className='education_column_resume experience'>
                                   <hr className='exprience_link'></hr>
                                   <div className='certificates_headin'>
                                        <h3 class="block-title">Experience</h3>
                                   </div>
                                   <div className='education_resume_col'>
                                        <hr className='eduction_link four'></hr>
                                        <h5 className="item-period">  2011 - Current</h5>
                                        <span class="eduction_title">Rolling Thunder</span>
                                        <span className='ests_website'><a href="https://expoundsofttechsolution.com/">Expound Soft Tech Solution Private Limited</a></span>
                                   </div>
                                   <div className='education_resume_col'>
                                        <hr className='eduction_link one'></hr>
                                        <h5 className="item-period">  2011 - Current</h5>
                                        <span class="eduction_title">Rolling Thunder</span>
                                        <h6 className='item-title'>Magento</h6>
                                        <p className='Text'>Magento 2 is an open-source E-commerce platform, which is created by Roy Rubin and Yoav Kutner under Varien Inc. Magento was first released on March 31, 2007. It is a useful software for the online business, and currently a largest E-commerce platform in the world. Magento 2 is very simple, versatile, and quick to use.</p>
                                   </div>
                                   <div className='education_resume_col'>
                                        <hr className='eduction_link two'></hr>
                                        <h5 className="item-period"> 2011 - Current</h5>
                                        <span class="eduction_title">University of Studies</span>
                                        <h6 className='item-title'>Shopify</h6>
                                        <p className='Text'>Shopify is a commerce platform that allows anyone to set up an online store and sell their products. Merchants can also sell their products in person with Shopify POS.Shopify started over ten years ago when our founder Tobi wanted to sell snowboards with his company at that time called Snowdevil. He quickly realized it was incredibly challenging to sell snowboards online without an easy to use ecommerce platform. So he decided to create it himself, and Shopify was born..</p>
                                   </div>
                                   <div className='education_resume_col'>
                                        <hr className='eduction_link three'></hr>
                                        <h5 className="item-period"> 2013 - Current</h5>
                                        <span class="eduction_title">University of Studies</span>
                                        <h6 className='item-title'>React JS</h6>
                                        <p className='Text'>AngularJS is a structural framework for dynamic web apps. It lets you use HTML as your template language and lets you extend HTML's syntax to express your application's components clearly and succinctly. AngularJS's data binding and dependency injection eliminate much of the code you would otherwise have to write. And it all happens within the browser, making it an ideal partner with any server technology.</p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div className='hireme'>
                    <h1 class="hiremehead">
                         <a href="https://www.upwork.com/freelancers/~0194704dd18a554e0e" target="_blank">
                              Hire Me</a></h1>
               </div>

               <Footer />
          </div>

     )
}
export default Resume;