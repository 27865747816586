import { Outlet, Link} from "react-router-dom";
import './Layout.css';
const Layout = () => {
  return (
    <>
    <div className="header_inner">
    <div className='logo'>
        <div className='logo_heading'>
        <Link  to="/">
          <div className='logo-symbol'>S</div>
          <div className='logo-text'>Suraj
            <span>Kumawat</span>
          </div>
          </Link>
        </div>

      </div>
      <nav className="navlayout">
        <ul className="ullayout">
         <li className="layoutli">
        <Link  to="/">About Me</Link>
        </li>
          <li className="layoutli">
            <Link to="/resume">Resume</Link>
          </li>
           <li className="layoutli technology">
            <Link to="/portfolio">Portfolio</Link>
          </li>
         <li className="layoutli">
            <Link to="/contact">Contact</Link>
          </li>
      
           </ul>
      </nav>
      </div>  
      <Outlet />
    </>
  )
};

export default Layout;