import './App.css';

import Home from './screen/Home';
import Menu from './screen/Menu';


const App = () => {
  return(

//  <Home />
<Menu />
 )
}

export default App;





